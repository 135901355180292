import Vue from 'vue'
import moment from 'moment'
import {isBoolean} from "bootstrap-vue/esm/utils/inspect";

Vue.filter('formatStatus', function (value) {
    return (  (value === 1) || (value === true) || (value != 0) ) ? 'ri-check-line ri-2x text-success' : '';
});

Vue.filter('formatSize', function (value) {
    if ( value ) {
        let sizeInMB = (value / (1024*1024)).toFixed(2);
        return sizeInMB + 'MB';
    }
});

Vue.filter('formatDate', function (value, time) {
    if (value && time) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss');
    }
    else if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    }
});

Vue.filter('formatPrice', function (value) {
    // Convert price to float for proper formatting
    let floatValue = parseFloat(value);
  
    // Always use 2 decimal places
    let decimals = 2;
  
    // Format the number using a comma for decimal point and a dot for thousands separator
    return floatValue.toLocaleString('de-DE', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    });
  });

Vue.filter('formatCountry', function (value) {
    if (_.isEmpty(value)) return '';
    
    let countries = JSON.parse(dataJSON.countries);
    let a = _.find(countries, function (o) {
        return (o.code === value);
    });
    
    return a && a.name ? a.name : '';
});

Vue.filter('formatAirlines', function (value) {
    return _.get(dataJSON.airlines, String(value+'.name'));
});

Vue.filter('formatAirports', function (value) {
    let name = _.get(dataJSON.airports, String(value+'.name'));
    let code = _.get(dataJSON.airports, String(value+'.code'));
    return name + ' (' + code + ')';
});

Vue.filter('formatId', function (value) {
    if (value) {
        return String(value).substring(4)
    }
});

Vue.filter('percentage', function (value, total) {
    return (value > 0) ? (value/total)*100 : 0;
});

Vue.filter('dataJSON', function (value, path) {
    let v = isBoolean( value ) ? ( value ) ? 1 : 0 : value,
        r = _.get(dataJSON.general, String(path+'.'+v));
    return (r) ? r : '';
});

Vue.filter('formatLabel', function (value) {
    let v = isBoolean( value ) ? ( value ) ? 1 : 0 : value;
    if( v === 1 ) return 'info';
    if( v === 2 ) return 'success';
    if( v === 3 ) return 'danger';
    if( v === 4 || v === 5 ) return 'warning';
    return 'light';
 });
